<template>
  <div class="card ">
    <div v-if="loading" class="card-body">
      <div class="text-center">
        <b-spinner variant="primary" label="Text Centered"></b-spinner>
      </div>
    </div>
    <template v-else>
      <div class="card-body p-sm-3">
        <b-alert
          :variant="msg.type"
          dismissible
          class="mt-3"
          v-model="msg.has"
          :show="msg.text"
          >{{ msg.text }}</b-alert
        >

        <!-- <pre>form: {{ form }}</pre> -->
        <!-- <pre>form.permissions: {{form.permissions}}</pre> -->
        <!-- <pre>permissions: {{permissions}}</pre> -->
        <!-- <pre>roleId: {{roleId}}</pre> -->

        <form @submit.prevent="handleSubmit" novalidate>
          <!-- name -->
          <div class="form-group mb-2">
            <label for="name" class="required">{{ $t("form.name") }}</label>
            <input
              class="form-control"
              v-model="form.name"
              id="name"
              :placeholder="$t('form.name-placeholder')"
              :readonly="type === 'view'"
              :class="[
                {
                  'is-invalid': submitted && $v.form.name.$error,
                },
                type === 'view' ? 'form-control-plaintext' : 'form-control',
              ]"
            />
            <div
              v-if="submitted && $v.form.name.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.form.name.required">{{
                $t("form.name-req")
              }}</span>
            </div>
          </div>

          <div class="row mt-md-3 text-center">
            <div class="col-sm-12 col-md-auto text-md-left">
              <b-form-checkbox
                v-model="showActive"
                :disabled="inprogress"
                class="py-1"
                switch
                >{{ $t("table.show-active") }}</b-form-checkbox
              >
            </div>
            <!-- Search -->
            <div class="col-sm-12 col-md-auto ml-auto mt-2 mt-md-0">
              <div
                id="tickets-table_filter"
                class="dataTables_filter text-md-right"
              >
                <label class="d-inline-flex align-items-center">
                  {{ $t("search") }}:
                  <b-form-input
                    v-model="filter"
                    type="search"
                    placeholder="..."
                    class="form-control form-control-sm ml-2"
                  ></b-form-input>
                </label>
              </div>
            </div>
            <!-- End search -->
          </div>

          <div class="table-responsive mb-0">
            <b-table
              :items="permissionsDisplayed"
              :fields="tableFields"
              responsive="xs"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
              class="permissions-table"
              striped
              small
              show-empty
            >
              <!-- action -->
              <template #head(action)>
                <template v-if="type !== 'view'">
                  <b-form-checkbox
                    v-model="toggleAll"
                    :disabled="inprogress"
                    switch
                    v-b-tooltip="{
                      trigger: 'hover',
                      placement: 'top',
                      boundary: 'document',
                      variant: 'danger',
                      title: 'Check / Uncheck all',
                    }"
                  />
                </template>
                <template v-else>
                  Action
                </template>
              </template>

              <!-- action -->
              <template #cell(action)="data">
                <b-form-checkbox
                  switch
                  :key="data.item.id"
                  :checked="checkPermission(data.item.id)"
                  :disabled="type === 'view' || inprogress"
                  @change="
                    togglePermission(data.item, checkPermission(data.item.id))
                  "
                />
              </template>

              <template #emptyfiltered="scope">
                <h5 class="text-center">
                  {{ scope.emptyFilteredText }}: {{ filter }}
                </h5>
              </template>
            </b-table>
          </div>

          <div
            v-if="showActive && permissionsDisplayed.length === 0"
            class="text-center"
          >
            <div class="mb-3">Has not active permissions...</div>
          </div>

          <!-- CREATE button -->
          <div v-if="type === 'create'" class="mt-4 text-center text-lg-right">
            <div class="form-group mb-0">
              <router-link to="/roles" class="btn btn-warning mx-2 mb-2">
                {{ $t("btn.cancel") }}
              </router-link>
              <button
                :disabled="inprogress"
                class="btn btn-primary mx-2 mb-2 mr-lg-0"
                type="submit"
              >
                {{ $t("role.add") }}
              </button>
            </div>
          </div>

          <!-- EDIT button -->
          <div v-if="type === 'edit'" class="mt-4 text-center text-lg-right">
            <div class="form-group mb-0">
              <router-link to="/roles" class="btn btn-warning mx-2 mb-2">
                {{ $t("btn.cancel") }}
              </router-link>
              <button
                :disabled="inprogress"
                class="btn btn-primary mx-2 mb-2 mr-lg-0"
                type="submit"
              >
                {{ $t("btn.save") }}
              </button>
            </div>
          </div>

          <!-- VIEW button -->
          <div v-if="type === 'view'" class="mt-4 text-center text-lg-right">
            <div class="form-group mb-0">
              <router-link to="/roles" class="btn btn-warning mx-2 mb-2">
                {{ $t("btn.cancel") }}
              </router-link>
              <router-link
                :to="`/roles/edit/${$route.params.id}`"
                class="btn btn-primary mx-2 mb-2 mr-lg-0"
                >{{ $t("btn.edit") }}</router-link
              >
            </div>
          </div>
        </form>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import { required } from "vuelidate/lib/validators";

export default {
  components: {},
  props: {
    type: {
      type: String, // create, edit, view
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      submitted: false,
      inprogress: false,
      msg: {
        has: false,
        type: "",
        text: "",
      },
      form: {
        name: "",
        permissions: [],
      },
      formInit: null,

      filter: null,
      filterOn: [],
      filteredItems: [],
      sortBy: "id",
      sortDesc: false,
      tableFields: [
        {
          key: "id",
          sortable: true,
          class: "cell-id",
        },
        {
          key: "name",
          sortable: true,
          label: "Permission",
        },
        {
          key: "action",
          class: "cell-action",
        },
      ],

      roleId: "",

      showActive: false,
      toggleAll: false,
    };
  },
  validations() {
    return {
      form: this.formRules,
    };
  },
  created() {
    this.loadData();
  },
  computed: {
    ...mapGetters(["permissions"]),
    formRules() {
      let rules = {};

      rules.name = {
        required,
      };

      return rules;
    },
    permissionsDisplayed() {
      // display only role permissions
      if (this.showActive) {
        return this.form.permissions;
      }

      // display all permissions
      return this.permissions;
    },
  },
  methods: {
    async loadData() {
      try {
        this.loading = true;
        await Promise.all([this.$store.dispatch("fetchPermissions")]);

        if (this.type === "edit" || this.type === "view") {
          await this.getRole(this.$route.params.id);
        }

        this.loading = false;
      } catch (error) {
        console.log("loadData Error: ", error);
      }
    },
    async getRole(id) {
      this.loading = true;

      await axios
        .get(this.$urls.URL_ROLES + "/" + id)
        .then((response) => {
          console.log(`response, role id (${id}): `, response);

          this.fillInitData(response);
          this.form = { ...this.formInit };
          this.loading = false;
        })
        .catch((error) => {
          console.log("getRole Error", error);
        });
    },
    async storeRole() {
      const formData = new FormData();
      formData.append("name", this.form.name);

      const response = await axios.post(this.$urls.URL_ROLES, formData, {
        headers: {
          "content-type": "multipart/form-data",
        },
      });

      // console.log("storeRole response: ", response);
      this.roleId = response.data.role.id;
    },
    async updateRole(id) {
      const formData = new FormData();
      formData.append("_method", "PATCH");
      formData.append("name", this.form.name);

      await axios.post(this.$urls.URL_ROLES + `/${id}`, formData, {
        headers: {
          "content-type": "multipart/form-data",
        },
      });
    },
    async givePermissions(id) {
      const givePermissions = this.form.permissions?.map((p) => p.id);

      if (!givePermissions.length) {
        return false;
      }

      const formData = new FormData();
      givePermissions?.map((id) => {
        formData.append("ids[]", id);
      });

      await axios.post(
        this.$urls.URL_ROLES + `/give-permission/${id}`,
        formData,
        {
          headers: {
            "content-type": "multipart/form-data",
          },
        }
      );
    },
    async revokePermissions(id) {
      const givePermissions = this.form.permissions?.map((p) => p.id);
      const revokePermissions = this.permissions
        ?.filter((p) => !givePermissions.includes(p.id))
        .map((p) => p.id);

      if (!revokePermissions.length) {
        return false;
      }

      const formData = new FormData();
      formData.append("_method", "DELETE");
      revokePermissions?.map((id) => {
        formData.append("ids[]", id);
      });

      await axios.post(
        this.$urls.URL_ROLES + `/revoke-permission/${id}`,
        formData,
        {
          headers: {
            "content-type": "multipart/form-data",
          },
        }
      );
    },
    fillInitData(response) {
      this.formInit = {
        name: response.data.name,
        permissions: response.data.permissions,
      };
    },
    async handleSubmit() {
      this.submitted = true;
      this.msg = {
        has: false,
        type: "",
        text: "",
      };

      this.$v.$touch();

      if (!this.$v.form.$invalid) {
        this.inprogress = true;

        if (this.type === "create") {
          try {
            await this.storeRole(),
              await this.givePermissions(this.roleId),
              (this.msg.has = true);
            this.msg.type = "success";
            this.msg.text = this.$t("role.msg-add-success");

            this.inprogress = false;
          } catch (error) {
            console.log("create role Error: ", error);

            console.log("Error: ", error);
            this.msg.has = true;
            this.msg.type = "danger";
            this.msg.text =
              error.response?.data?.message || this.$t("role.msg-add-error");

            this.inprogress = false;

            if (error.response.data.errors) {
              for (const [key, value] of Object.entries(
                error.response.data.errors
              )) {
                this.msg.text += `[${key}: ${value}] `;
              }
            }
          }
        }

        if (this.type === "edit") {
          try {
            await this.updateRole(this.$route.params.id),
              await this.givePermissions(this.$route.params.id),
              await this.revokePermissions(this.$route.params.id),
              (this.msg.has = true);
            this.msg.type = "success";
            this.msg.text = this.$t("role.msg-edit-success");

            this.inprogress = false;
          } catch (error) {
            console.log("create role Error: ", error);

            console.log("Error: ", error);
            this.msg.has = true;
            this.msg.type = "danger";
            this.msg.text =
              error.response?.data?.message || this.$t("role.msg-edit-error");

            this.inprogress = false;

            if (error.response.data.errors) {
              for (const [key, value] of Object.entries(
                error.response.data.errors
              )) {
                this.msg.text += `[${key}: ${value}] `;
              }
            }
          }
        }
      }

      this.$scrollToTop();
    },
    checkPermission(id) {
      return !!this.form.permissions.find((p) => p.id === id);
    },
    togglePermission(permission, checked) {
      if (checked) {
        this.form.permissions = this.form.permissions.filter(
          (p) => p.id !== permission.id
        );
      } else {
        this.form.permissions.push(permission);
      }
    },
    onFiltered(filteredItems) {
      // console.log("filteredItems: ", filteredItems);
      this.filteredItems = filteredItems;
    },
  },
  watch: {
    toggleAll() {
      if (this.toggleAll) {
        if (this.filter) {
          this.form.permissions = [
            ...this.form.permissions,
            ...this.filteredItems,
          ];
        } else {
          this.form.permissions = [...this.permissions];
        }
      } else {
        if (this.filter) {
          const filteredItemsIds = this.filteredItems.map((i) => i.id);
          this.form.permissions = this.form.permissions.filter(
            (p) => !filteredItemsIds.includes(p.id)
          );
        } else {
          this.form.permissions = [];
        }
      }

      // only unique
      this.form.permissions = [
        ...new Map(
          this.form.permissions.map((item) => [item["id"], item])
        ).values(),
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.btn {
  min-width: 180px;
}

.form-control-plaintext {
  background: transparent;
  border: none;
  color: #000;
}
</style>
